import Vue from 'vue'
import Vuex from 'vuex'
import config from "../config";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        mainSite: config.main,
        site_name: config.name,
        menuOpen: false,
        profile: '',
        currentIndex: "",
        lastPage: "",
        unread: 0,
        wx: null,
        navigation: [{
            name: "首页",
            eng: 'Home',
            link: "/"
        }, {
            name: "人物",
            eng: 'People',
            link: "/people"
        }, {
            name: "作品",
            eng: 'Work',
            link: "/works"
        }, {
            name: "系列",
            eng: 'Series',
            link: "/series"
        }, {
            name: "外译",
            eng: 'Foreign',
            link: "/works/export"
        }, {
            name: "图书",
            eng: 'Book',
            link: "/books"
        }, {
            name: "书系",
            eng: 'Omnibus',
            link: "/omnibus"
        }, {
            name: "杂志",
            eng: 'Magazine',
            link: "/magazines"
        }, {
            name: "网媒",
            eng: 'Online',
            link: "/platforms"
        }, {
            name: "影视",
            eng: 'Movie',
            link: "/movies"
        }, {
            name: "组织",
            eng: 'Org',
            link: "/organizations"
        }, {
            name: "奖项",
            eng: 'Award',
            link: "/awards"
        }, {
            name: "主题",
            eng: 'Theme',
            link: "/themes"
        }, {
            name: "专题",
            eng: 'Topic',
            link: "/topics"
        }, {
            name: "辞典",
            eng: 'Dict',
            link: "/keywords",
            // adminOnly: true
        }]
    },
    mutations: {
        setWechatController(state, wx) {
            state.wx = wx
        },
        updateProfile(state, profile = "") {
            state.profile = profile;
        },
        updateNavigation (state) {

        },
        updateUnread (state, unread) {
            state.unread = unread;
        },
        toggleMenu (state, newState) {
            if ([true, false].includes(newState)) {
                state.menuOpen = newState;
            }
            else {
                state.menuOpen = !state.menuOpen;
            }
        },
        updateLastPage(state, page) {
            state.lastPage = page;
        },
        updateCurrentIndex(state, to) {
            for(let i = 0; i < state.navigation.length; i++) {
                if (state.navigation[i].link === to) {
                    state.currentIndex = `${i}`;
                }
            }
        }
    }
})
